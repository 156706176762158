import { formatDateYear } from "../../../../utils/utils";
import { pointsByEmployeeApi } from "../../pointsByEmployee/apis/pointsByEmployeeApi";

export const redeemPointsApi = pointsByEmployeeApi.injectEndpoints({
  endpoints: (builder) => ({
    getRedeemPoints: builder.query({
      query: ({ from, to }) => {
        if (from !== "" || to !== "") {
          return `/redeem-points?startdate=${formatDateYear(
            from
          )}&enddate=${formatDateYear(to)}`;
        }
      },
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({
                type: "Redeem",
                id,
              })),
              { type: "Redeem", id: "LIST" },
            ]
          : [{ type: "Redeem", id: "LIST" }],
    }),
    getRedeemPointEmployee: builder.query({
      query: ({ employeeId, from, to }) => {
        if (from !== "" || to !== "") {
          return `/redeem-points/employees/${employeeId}?startdate=${formatDateYear(
            from
          )}&enddate=${formatDateYear(to)}`;
        }
      },
      providesTags: () => [{ type: "Redeem", id: "DETAIL" }],
    }),
    getRewardEmployees: builder.query({
      query: () => "/employees",
    }),
    deleteRewardPoints: builder.mutation({
      query: ({ redeemId }) => ({
        url: `/redeem-points/${redeemId}`,
        method: "DELETE",
      }),
      invalidatesTags: () => [
        { type: "Redeem", id: "LIST" },
        { type: "Redeem", id: "UPDATED" },
      ],
    }),
    createRewardPoints: builder.mutation({
      query: ({ body }) => ({
        url: `/redeem-points`,
        method: "POST",
        body,
      }),
      invalidatesTags: () => [
        { type: "Redeem", id: "LIST" },
        { type: "Redeem", id: "UPDATED" },
      ],
    }),
    updateRewardPoints: builder.mutation({
      query: ({ redeemId, body }) => ({
        url: `/redeem-points/${redeemId}`,
        method: "PUT",
        body,
      }),
      invalidatesTags: () => [
        { type: "Redeem", id: "DETAIL" },
        { type: "Redeem", id: "LIST" },
        { type: "Redeem", id: "UPDATED" },
      ],
    }),
  }),
});

export const {
  useGetRedeemPointsQuery,
  useGetRedeemPointEmployeeQuery,
  useGetRewardEmployeesQuery,
  useDeleteRewardPointsMutation,
  useUpdateRewardPointsMutation,
  useCreateRewardPointsMutation,
} = redeemPointsApi;
