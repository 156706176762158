import { useEffect, useState, useContext } from "react";
import Label from "../../../components/Label";
import Input from "../../../components/Input";
import { useForm } from "../../../hooks/useForm";
import {
  availableYears,
  formatDateYear,
  initialFilters,
} from "../../../utils/utils";
import { toastError } from "../../../utils/alerts";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { InputDate } from "../../../components/InputDate";
import { InputSelect } from "../../../components/InputSelect";
import Select from "../../../components/Select";
import Form from "../../../components/Form";
import Button from "../../../components/Button";
import Loader from "../../../components/Loader";
import {
  useCreateRewardPointsMutation,
  useGetRedeemPointEmployeeQuery,
  useUpdateRewardPointsMutation,
} from "../../../store/slices/redeemPoints";
import { useGetPointsByManagerQuery } from "../../../store/slices/pointsByEmployee/apis/pointsByEmployeeApi";
import userContext from "../../../config/contextUser";

export const RedeemDetail = ({
  options,
  redeemDetail: dataModal,
  optionUsernames,
  handleCloseModal,
}) => {
  const user = useContext(userContext).user;

  const {
    onResetForm,
    onSetForm,
    formState: form,
    onInputChange: handleInputChange,
  } = useForm({
    remainingPoints: "",
    redeemDate: "",
    redeemPoints: "",
    year: "",
  });

  const [totalRemaining, setTotalRemaining] = useState(0);
  const [years, setYears] = useState([]);
  const [initialFilter, setInitialFilters] = useState({
    employeeId: "",
    from: "",
    to: "",
  });

  const [errorValidations, setErrorValidations] = useState({
    redeemPoints: "",
  });

  const {
    isLoading: loadingRedeem,
    error,
    data: employeePoints,
  } = useGetRedeemPointEmployeeQuery(
    { ...initialFilter },
    {
      skip: initialFilter.employeeId === "",
    }
  );

  const [
    createRewardPoints,
    { isLoading: isLoadingCreate, isSuccess: isSuccessCreated },
  ] = useCreateRewardPointsMutation();

  const [
    updateRewardPoints,
    { isLoading: isUpdating, isSuccess: isSuccessUpdated },
  ] = useUpdateRewardPointsMutation();

  const { isLoading: isLoadingAfterSave } = useGetPointsByManagerQuery(
    {
      managerId: user.employeeId,
      from: initialFilter.from,
      to: initialFilter.to,
    },
    {
      skip: !isSuccessUpdated || isSuccessCreated,
    }
  );

  const findEmployee = () =>
    optionUsernames.find((user) => user.label === form.employeId);

  const handleCreate = async () => {
    //Additional actions like send data to server
    if (typeof +form.redeemPoints !== "number") {
      toastError("Please check the required fields");
      return;
    }

    if (+form.redeemPoints > +form.remainingPoints) {
      setErrorValidations((errorState) => ({
        ...errorState,
        redeemPoints:
          "The redeem points should not be greater than the remainig points",
      }));
      toastError("Please check the required fields");
      return;
    }

    try {
      const body = {
        employeeIdKey: initialFilter.employeeId,
        redeemPoints: +form.redeemPoints,
        redeemDate: form.redeemDate,
      };
      // TODO: When the API is integrated, we should take 'edit' option
      // to send to the PUT endpoint
      await createRewardPoints({ body });
      onResetForm();
    } catch (error) {
      throw new Error("Error sending data `handle submit`");
    } finally {
      handleCloseModal();
    }
  };

  const handleUpdate = async () => {
    if (typeof +form.redeemPoints !== "number") {
      toastError("Please check the required fields");
      return;
    }

    if (+form.redeemPoints > totalRemaining) {
      setErrorValidations((errorState) => ({
        ...errorState,
        redeemPoints: `The redeem points should not be greater than ${totalRemaining}`,
      }));
      toastError(`Please check the required fields`);

      return;
    }
    try {
      const body = {
        redeemPointsId: dataModal.redeemPointsId,
        employeeIdKey: initialFilter.employeeId,
        redeemPoints: +form.redeemPoints,
        redeemDate: form.redeemDate,
      };
      await updateRewardPoints({
        redeemId: body.redeemPointsId,
        body,
      });
    } catch (error) {
      throw new Error(`handleUpdate() : ${JSON.stringify(error)}`);
    } finally {
      handleCloseModal();
    }
  };

  useEffect(() => {
    if (employeePoints) {
      if (options.type === "edit") {
        setTotalRemaining(
          dataModal.redeemPoints + employeePoints.currentBalance
        );
      }
      onSetForm({
        ...form,
        remainingPoints: employeePoints.currentBalance,
        redeemDate: dataModal?.redeemDate,
        redeemPoints: options.type === "edit" ? dataModal.redeemPoints : "",
      });
    }
    // eslint-disable-next-line
  }, [employeePoints]);

  useEffect(() => {
    const { from, to } = initialFilters(form.year);
    setInitialFilters({
      ...initialFilter,
      from,
      to,
    });
    // eslint-disable-next-line
  }, [form.year]);

  useEffect(() => {
    if (error) {
      toastError(`Doesn't have assigned points`);
    }
    // eslint-disable-next-line
  }, [error]);

  useEffect(() => {
    const employee = findEmployee();
    if (employee && options.type === "new") {
      const { value } = employee;
      setInitialFilters({
        ...initialFilter,
        employeeId: value,
      });
      return;
    }

    if (options.type === "edit") {
      const { from, to } = initialFilters(form.year);
      setInitialFilters({
        from,
        to,
        employeeId: dataModal.employeeIdKey,
      });
    }
    // eslint-disable-next-line
  }, [form.employeId]);

  useEffect(() => {
    // onResetForm();

    const currentYears = availableYears().map((item) => ({
      value: item,
      label: item,
    }));
    setYears(currentYears);
    onSetForm({
      ...form,
      year:
        options.type === "edit" && dataModal?.redeemDate
          ? new Date(dataModal.redeemDate).getUTCFullYear()
          : new Date().getUTCFullYear(),
    });

    return () => {
      setInitialFilters({
        employeeId: "",
        from: "",
        to: "",
      });
    };
    // eslint-disable-next-line
  }, []);

  // Form Validations
  useEffect(() => {
    if (options.type === "edit") {
      if (typeof +form.redeemPoints !== "number") {
        setErrorValidations((errorState) => ({
          ...errorState,
          redeemPoints: "The redeem points should be a number",
        }));
        return;
      }

      if (+form.redeemPoints > totalRemaining) {
        setErrorValidations((errorState) => ({
          ...errorState,
          redeemPoints: `The redeem points should not be greater than ${totalRemaining}`,
        }));
        return;
      }
      setErrorValidations((errorState) => ({
        ...errorState,
        redeemPoints: "",
      }));
      return;
    }

    if (+form.redeemPoints > +form.remainingPoints) {
      setErrorValidations((errorState) => ({
        ...errorState,
        redeemPoints:
          "The redeem points should not be greater than the remainig points",
      }));
      return;
    }
    setErrorValidations((errorState) => ({
      ...errorState,
      redeemPoints: "",
    }));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form.redeemPoints]);

  return isLoadingCreate || isUpdating || isLoadingAfterSave ? (
    <Loader />
  ) : (
    <section className="redeem-points__form">
      <Form
        classes="center"
        onSubmit={options.type === "new" ? handleCreate : handleUpdate}
      >
        <Label type="grid">
          Employee
          <div className="input-spin">
            <InputSelect
              name="employeId"
              autoComplete="off"
              placeholder="Type a name ..."
              onChange={(e) => {
                handleInputChange(e);
              }}
              disabled={options.type === "edit" || loadingRedeem}
              value={
                options.type === "edit"
                  ? dataModal.employeeName
                  : optionUsernames[
                      optionUsernames.findIndex(
                        (user) =>
                          user.label === form.employeId ||
                          user.value === Number(form.employeId)
                      )
                    ]?.label
              }
              options={optionUsernames}
            />
            <div className={!loadingRedeem ? "v-hidden" : ""}>
              <FontAwesomeIcon icon="spinner" spinPulse />
            </div>
          </div>
        </Label>
        <Label type="grid">
          Year
          <Select
            onChange={(e) => {
              handleInputChange(e);
            }}
            name="year"
            required
            disabled={loadingRedeem}
            selectedValue={form.year}
            options={years}
          />
        </Label>

        {!error && employeePoints?.employeeIdKey && (
          <>
            <Label type="grid">
              Earned Points
              <Input
                type="number"
                placeholder="Earned Points"
                name="earnedPoints"
                required
                min={0}
                disabled
                value={employeePoints?.earnedPoints}
              />
            </Label>
            <Label type="grid">
              Remaining Points
              <Input
                type="number"
                placeholder="Remaining Points"
                name="remainingPoints"
                min={0}
                disabled
                value={employeePoints?.currentBalance}
                required
              />
            </Label>
            <Label type="grid">
              Points to Redeem
              <Input
                type="number"
                placeholder="Points to Redeem"
                name="redeemPoints"
                required
                min={0}
                value={form.redeemPoints}
                onChange={(e) => handleInputChange(e)}
              />
            </Label>
            <div className="message">
              <center>
                <small className="warning">
                  {errorValidations.redeemPoints}
                </small>
              </center>
            </div>
            <Label type="grid">
              Redeem Date
              <InputDate
                name="redeemDate"
                value={form.redeemDate}
                min={formatDateYear(initialFilter.from)}
                max={formatDateYear(initialFilter.to)}
                required
                onChange={(e) => handleInputChange(e)}
              />
            </Label>
          </>
        )}

        <div className="form__actions">
          <Button type="button" secondary onClick={() => handleCloseModal()}>
            Cancel
          </Button>
          <Button type="submit">Save</Button>
        </div>
      </Form>
    </section>
  );
};
